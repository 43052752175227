<template>
  <b-card v-if="users" no-body class="card-company-table">
    <b-table :items="users" responsive :fields="fields" class="mb-0">
      <!-- Id -->
      <template #cell(id)="data">
        <span class="font-weight-bolder mb-12">#{{ data.index + 1 }}</span>
      </template>

      <!-- Question File -->
      <template #cell(questionFile)="data">
        <a :href="data.item.questionFile.url" target="_blank">
          <img
            class="font-weight-bolder mb-12"
            style="max-height: 150px"
            :src="data.item.questionFile.url"
          />
        </a>
      </template>

      <!-- Solution File -->
      <template #cell(solutionFile)="data">
        <b-button
          variant="flat-primary"
          :href="data.item.solutionFile.url"
          target="_blank"
        >
          <feather-icon icon="PlayIcon" class="mr-50" />
          <span class="align-middle">Görüntüle</span>
        </b-button>
      </template>

      <!-- Lesson -->
      <template #cell(lesson)="data">
        <span class="font-weight-bolder mb-12">{{
          data.item.lesson.name
        }}</span>
      </template>

      <!-- Answer -->
      <template #cell(answer)="data">
        <span class="font-weight-bolder mb-12">{{
          data.item.answers.filter((x) => x.isTrue == true)[0].text
        }}</span>
      </template>
      <!-- Actions -->
      <template #cell(actions)="data">
        <b-button variant="flat-warning" @click="editExam(data.item)">
          <feather-icon icon="EditIcon" class="mr-50" />
          <span class="align-middle">Düzenle</span>
        </b-button>
        <b-button variant="flat-danger" @click="deleteExam(data.item)">
          <feather-icon icon="DeleteIcon" class="mr-50" />
          <span class="align-middle">Sil</span>
        </b-button>
      </template>
    </b-table>

    <!--Open Add Exam-->
    <b-sidebar
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      v-model="addExamPanel"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Yeni Soru Ekle</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <b-form class="p-2" @submit.prevent>
          <b-form-group label="Alt Ders " label-for="vue-select">
            <v-select
              id="vue-select"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :reduce="(lesson) => lesson.id"
              :options="lessons"
              v-model="addExamQuestion.childLessonId"
            />
          </b-form-group>
          <b-form-group label="Soru Resmi" label-for="name">
            <b-form-input
              name="firstName"
              id="name"
              type="text"
              v-model="addExamQuestion.questionFile"
            />
          </b-form-group>
          <b-form-group label="Çözüm Videosu" label-for="name">
            <b-form-input
              name="firstName"
              id="name"
              type="text"
              v-model="addExamQuestion.solutionFile"
            />
          </b-form-group>
          <b-form-group label="Doğru Cevap " label-for="vue-select">
            <v-select
              id="vue-select"
              v-model="addExamQuestion.isCorrect"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(answers) => answers.key"
              label="key"
              :options="answers"
            />
          </b-form-group>
          <b-form-group label="Kazanım" label-for="name">
            <b-form-textarea
              name="firstName"
              id="name"
              type="text"
              v-model="addExamQuestion.gain"
            />
          </b-form-group>
          <b-form-group label="A-Kitapcık Sırası" label-for="name">
            <b-form-input
              name="firstName"
              id="name"
              type="number"
              v-model="addExamQuestion.viewOrder"
            />
          </b-form-group>
          <b-form-group label="B-Kitapcık Sırası" label-for="name">
            <b-form-input
              name="firstName"
              id="name"
              type="number"
              v-model="addExamQuestion.bOrder"
            />
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              @click="addQuestion"
            >
              Ekle
            </b-button>
            <b-button variant="outline-secondary" @click="hide">
              Vazgeç
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>

    <b-modal v-model="showUpload" title="Excell Soru Yükleme" hide-footer>
      <p>
        Örnek Şablonu
        <a
          href="https://api.uzaktanokulsistemi.com/sablon/SABLON-DENEME-SINAVI.xlsx"
          target="_blank"
          >buradan</a
        >
        indirebilirsiniz.<br />
      </p>
      <p>
        <b-button
          block
          variant="primary"
          class="font-weight-bolder mb-12"
          @click="$refs.files.click()"
          >YÜKLE</b-button
        >
      </p>
    </b-modal>
    <input
      style="display: none"
      type="file"
      id="files"
      ref="files"
      v-on:change="handleFileUploads()"
    />

    <!--Open Edit Exam-->
    <b-sidebar
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      v-model="editExamPanel"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Test Düzenle</h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <!-- Body -->
        <b-form class="p-2" @submit.prevent>
          <b-form-group label="Alt Ders " label-for="vue-select">
            <v-select
              id="vue-select"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :reduce="(lesson) => lesson.id"
              :options="lessons"
              v-model="editExamQuestion.childLessonId"
            />
          </b-form-group>
          <b-form-group label="Soru Resmi" label-for="name">
            <b-form-input
              name="firstName"
              id="name"
              type="text"
              v-model="editExamQuestion.questionFile"
            />
          </b-form-group>
          <b-form-group label="Çözüm Videosu" label-for="name">
            <b-form-input
              name="firstName"
              id="name"
              type="text"
              v-model="editExamQuestion.solutionFile"
            />
          </b-form-group>
          <b-form-group label="Doğru Cevap " label-for="vue-select">
            <v-select
              id="vue-select"
              v-model="editExamQuestion.isCorrect"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(answers) => answers.key"
              label="key"
              :options="answers"
            />
          </b-form-group>
          <b-form-group label="Kazanım" label-for="name">
            <b-form-textarea
              name="firstName"
              id="name"
              type="text"
              v-model="editExamQuestion.gain"
            />
          </b-form-group>
          <b-form-group label="A-Kitapcık Sırası" label-for="name">
            <b-form-input
              name="firstName"
              id="name"
              type="number"
              v-model="editExamQuestion.viewOrder"
            />
          </b-form-group>
          <b-form-group label="B-Kitapcık Sırası" label-for="name">
            <b-form-input
              name="firstName"
              id="name"
              type="number"
              v-model="editExamQuestion.bOrder"
            />
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              @click="updateQuestion"
            >
              Güncelle
            </b-button>
            <b-button variant="outline-secondary" @click="hide">
              Vazgeç
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BSidebar,
  BTooltip,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BMediaBody,
  BMedia,
  BMediaAside,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroup,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import XLSX from "xlsx";
export default {
  components: {
    flatPickr,
    vSelect,
    ToastificationContent,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
  },
  data() {
    return {
      fields: [
        { key: "id", label: "Ref" },
        { key: "viewOrder", label: "A-Kitapçık" },
        { key: "bOrder", label: "B-Kitapçık" },
        { key: "questionFile", label: "Soru" },
        { key: "solutionFile", label: "Çözüm Videosu" },
        { key: "answer", label: "Doğru Cevap" },
        { key: "gains", label: "Kazanım" },
        { key: "lesson", label: "Alt Ders" },
        { key: "actions", label: "Detaylar" },
      ],
      answers: [
        { key: "A" },
        { key: "B" },
        { key: "C" },
        { key: "D" },
        { key: "E" },
      ],
      users: [],
      lessons: [],
      addExamPanel: false,
      editExamPanel: false,
      showUpload: false,
      addExamQuestion: {
        examId: "",
        lessonId: "",
        childLessonId: "",
        questionFile: "",
        solutionFile: "",
        gain: "",
        viewOrder: 1,
        bOrder: 1,
        isCorrect: "A",
      },
      editExamQuestion: {
        examId: "",
        lessonId: "",
        childLessonId: "",
        questionId: "",
        questionFile: "",
        solutionFile: "",
        gain: "",
        viewOrder: 1,
        bOrder: 1,
        isCorrect: "A",
      },
    };
  },
  mounted() {
    this.$root.$on("AddButtonClick", () => {
      this.addExamPanel = true;
    });
    this.$root.$on("AddUploadClick", () => {
      this.showUpload = true;
    });
  },
  created() {
    this.getData();
    this.getLessons();
  },
  methods: {
    async getData() {
      var users = await this.$http.get(
        "Exam/Question/" +
          this.$route.params.examId +
          "/" +
          this.$route.params.lessonId
      );
      this.users = users.data.data;
    },
    async getLessons() {
      var lessons = await this.$http.get(
        "Exam/ChildLesson/" + this.$route.params.id
      );
      this.lessons = lessons.data.data;
    },
    processExcel(data) {
      var workbook = XLSX.read(data, {
        type: "binary",
      });

      var result = {};
      var index= 0;
      workbook.SheetNames.forEach(function (sheetName) {
        var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
        });
        if (roa.length) result[index] = roa;
        index++;
      });
      return result;
    },
    handleFileUploads() {
      var file = this.$refs.files.files[0];

      if (file) {
        var reader = new FileReader();
        reader.onload = async (e) => {
          var contents = this.processExcel(e.target.result);
          var questions = contents[0];
          for (let index = 1; index < questions.length; index++) {
            const row = questions[index];
            var childLesson = this.lessons.filter(x=>x.name == row[7]);
            var request = {
                examId: this.$route.params.examId,
                lessonId:  this.$route.params.lessonId,
                viewOrder:parseInt(row[1]),
                bOrder:parseInt(row[2]),
                questionFile:row[3],
                solutionFile:row[4],
                isCorrect:row[5],
                gain:row[6],
                childLessonId:childLesson.length > 0 ? childLesson[0].id : null
            };

            await this.$http.post("Exam/AddExamQuestion", request);
          }
          this.getData();
          this.showUpload = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "İşlem",
              icon: "BellIcon",
              text: "İşlem Başarılı Soru Oluşturuldu",
              variant: "success",
            },
          });
        };
        reader.readAsBinaryString(file);
      } else {
        console.log("Failed to load file");
      }
    },
    
    addQuestion() {
      var request = this.addExamQuestion;
      request.examId = this.$route.params.examId;
      request.lessonId = this.$route.params.lessonId;
      request.viewOrder = parseInt(request.viewOrder);
      request.bOrder = parseInt(request.bOrder);
      this.$http
        .post("Exam/AddExamQuestion", request)
        .then((data) => {
          this.getData();
          this.addExamPanel = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "İşlem",
              icon: "BellIcon",
              text: "İşlem Başarılı Soru Oluşturuldu",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          //console.log(error.response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Hata",
              icon: "BellIcon",
              text: error.response.data.error.message,
              variant: "danger",
            },
          });
        });
    },
    updateQuestion() {
      var request = this.editExamQuestion;
      request.viewOrder = parseInt(request.viewOrder);
      request.bOrder = parseInt(request.bOrder);
      this.$http
        .put("Exam/UpdateExamQuestion", request)
        .then((data) => {
          this.getData();
          this.editExamPanel = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "İşlem",
              icon: "BellIcon",
              text: "İşlem Başarılı Soru Güncellendi",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          //console.log(error.response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Hata",
              icon: "BellIcon",
              text: error.response.data.error.message,
              variant: "danger",
            },
          });
        });
    },
    editExam(data) {
      this.editExamQuestion = {
        examId: this.$route.params.examId,
        lessonId: this.$route.params.lessonId,
        childLessonId: data.lesson.id,
        questionId: data.id,
        questionFile: data.questionFile.url,
        solutionFile: data.solutionFile.url,
        gain: data.gains,
        viewOrder: data.viewOrder,
        bOrder: data.bOrder,
        isCorrect: data.answers.filter((x) => x.isTrue == true)[0].text,
      };
      this.editExamPanel = true;
    },
    deleteExam(data) {
      this.$confirm({
        title: "Sil",
        message: `Silmek İstediğinizden Emin misiniz?`,
        button: {
          no: "Vazgeç",
          yes: "Sil",
        },
        callback: async (confirm) => {
          if (confirm) {
            await this.$http.delete(
              "Exam/DeleteQuestion/" +
                this.$route.params.examId +
                "/" +
                this.$route.params.lessonId +
                "/" +
                data.id
            );
            this.getData();
          }
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
